import React, { useState, useRef, useEffect } from 'react';
import { BsChatLeftTextFill } from "react-icons/bs";
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';

import './chat.css';

const Chat = () => {
  const [connection, setConnection] = useState(null);
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');

  const chatBoxRef = useRef(null);

  // Establish SignalR connection and handle incoming messages
  useEffect(() => {
    const connect = async () => {
      try {
        const token = localStorage.getItem('token'); 
        const hubConnection = new HubConnectionBuilder()
          .withUrl(`${process.env.REACT_APP_API_BASE_URL}/chatHubService`, {
            accessTokenFactory: () => token, 
            withCredentials: false,
          })
          .configureLogging(LogLevel.Information)
          .withAutomaticReconnect()
          .build();

        hubConnection.on('newMessage', (storedMessage) => {
          setMessages(prevMessages => [...prevMessages, { sender: storedMessage.sender, text: storedMessage.message }]);
        });

        await hubConnection.start();
        console.log('Connected to SignalR Hub');
        setConnection(hubConnection);
      } catch (err) {
        console.error('Connection error:', err);
      }
    };

    if (!connection) {
      connect();
    }

    return () => {
      if (connection) {
        connection.off('newMessage'); // Clean up event listener
        connection.stop().catch(err => console.error('Disconnection error:', err));
      }
    };
  }, [connection]);

  // Scroll chat box to the bottom whenever messages change
  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages]);

  // Send new message via SignalR and locally update messages list
  const handleSendMessage = async () => {
    const employeeName = localStorage.getItem('employeeName');
    
    if (newMessage.trim()) {
      if (connection) {
        try {
          await connection.invoke('SendMessage', newMessage);
          setMessages([...messages, { sender: employeeName || 'Unknown', text: newMessage }]); // Use employeeName or 'Unknown' if it's not set
          setNewMessage(''); // Clear input after sending
        } catch (err) {
          console.error('Error sending message:', err);
        }
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage();
    }
  };

  return (
    <div className="chat-container">
      <div className="header2">
        <div className="header-content">
          <BsChatLeftTextFill className="header-icon" />
          <h1 className="header-text">المحادثات</h1>
        </div>
        <div className="actions1"></div>
      </div>
      

      <div className="chat-box" ref={chatBoxRef}>
        {messages.map((message, index) => (
          <div key={index} className="message-container">
            <div className="sender-name">{message.sender}</div>
            <div className="message">{message.text}</div>
          </div>
        ))}
      </div>

      <div className="input-containerC">
        <input
          className='inputC'
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="اكتب رسالة..."
        />
        <button className='chatb' onClick={handleSendMessage}>إرسال</button>
      </div>
    </div>
  );
};

export default Chat;
