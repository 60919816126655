import React, { useEffect, useState } from 'react';
import { IoNotificationsSharp } from "react-icons/io5";
import pencil from '../images/pencil.svg';
import './notification.css';
import axios from 'axios';
import Dropform from './dropform';


const Notification = () => {
  const [myNotifications, setMyNotifications] = useState([]);
  const [Notifications, setNotifications] = useState([]);
  const [error, setError] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [recipientsList, setRecipientsList] = useState([]);
  const [notificationData, setNotificationData] = useState({
    startDate: '',
    endDate: '',
    topic: '',
    details: '',
    to: '',
  });

  const token = localStorage.getItem('token');

  const handleRowClick = (item) => {
    setSelectedItem(item);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNotificationData({
      ...notificationData,
      [name]: value,
    });
  };


  const handleFormSubmit = async () => {
    try {
      console.log(notificationData);
      // Convert the dates from local to ISO 8601 with UTC (Z)
      const startDateISO = new Date(notificationData.startDate).toISOString();
      const endDateISO = new Date(notificationData.endDate).toISOString();
      
      const payload = {
        ...notificationData,
        startDate: startDateISO, // use converted date
        endDate: endDateISO,     // use converted date
        to: notificationData.to.split(',').map((email) => email.trim()), // convert to array
      };
  
      console.log(payload); // Check the payload in the console
  
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/general/addnotification`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        // Refresh notifications or show success message
        setIsFormOpen(false);
      }
    } catch (error) {
      console.error('Error creating notification:', error);
    }
  };
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log(notificationData);
        const token = localStorage.getItem('token');
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/mail/forwarding-options`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        // Extract names from response data
        const extractNames = (data) => {
          const departmentNames = data.departments.map(department => department.name);
          const sectionNames = data.sections.map(section => section.name);
          const employeeNames = data.employees.map(employee => employee.name);
          return [...departmentNames, ...sectionNames, ...employeeNames];
        };

        let names = extractNames(response.data);
        names.unshift("الكل"); // Add "الكل" at the top of the names array
        setRecipientsList(names);
      } catch (err) {
        setError('Failed to fetch data. Please try again.');
        console.error('Fetch error:', err);
      }
    };

    fetchData();
  }, []);




  const handleDropdownChange = (selectedValues) => {
    setNotificationData({
      ...notificationData,
      to: selectedValues, // Update the 'to' field with selected dropdown values
    });
  };
  






  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/general/getmynoti`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (Array.isArray(response.data)) {
          setMyNotifications(response.data);
        } else {
          setMyNotifications([]);
        }
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };

    fetchNotifications();
  }, [token]);

  useEffect(() => {
    const fetchNotifications2 = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/general/getnotifications`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (Array.isArray(response.data)) {
          setNotifications(response.data);
        } else {
          setNotifications([]);
        }
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };

    fetchNotifications2();
  }, [token]);

  return (
    <div className="user-list-container">
      {!selectedItem ? (
        <>
          <div className="header2">
            <div className="header-content">
              <IoNotificationsSharp alt="Icon" className="header-icon" />
              <h1 className="header-text2">التبليغات</h1>
            </div>
            <div className="actions1">
              <button className="edit-button" onClick={() => setIsFormOpen(true)}>
                <img src={pencil} alt="add" className="button-icon" />
                إنشاء تبليغ
              </button>
            </div>
          </div>

          {/* Table for "My Notifications" */}
          <div className="table-section1">
            <h2 className="table-title">التبليغات الخاصة بي</h2>
            <table className="notification-table">
              <thead>
                <tr>
                  <th>المرسل</th>
                  <th>الموضوع</th>
                  <th>تاريخ البدء</th>
                  <th>تاريخ الانتهاء</th>
                </tr>
              </thead>
              <tbody>
                {myNotifications.length > 0 ? (
                  myNotifications.map((notification, index) => (
                    <tr
                      key={index}
                      onClick={() => handleRowClick(notification)}
                      className={`clickable-row ${selectedItem?.id === notification.id ? 'selected-row' : ''}`}
                    >
                      <td>{notification.owner.name}</td>
                      <td>{notification.topic}</td>
                      <td>{new Date(notification.startDate).toLocaleString('ar-GB', {
                        weekday: 'long',
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true
                      })}</td>
                      <td>{new Date(notification.endDate).toLocaleString('ar-GB', {
                        weekday: 'long',
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true
                      })}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4">لا توجد تبليغات</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {/* Table for "Notifications" */}
          <div className="table-section1">
            <h2 className="table-title">التبليغات</h2>
            <table className="notification-table">
              <thead>
                <tr>
                  <th>المرسل</th>
                  <th>الموضوع</th>
                  <th>تاريخ البدء</th>
                  <th>تاريخ الانتهاء</th>
                </tr>
              </thead>
              <tbody>
                {Notifications.length > 0 ? (
                  Notifications.map((notification, index) => (
                    <tr
                      key={index}
                      onClick={() => handleRowClick(notification)}
                      className={`clickable-row ${selectedItem?.id === notification.id ? 'selected-row' : ''}`}

                    >
                      <td>{notification.owner.name}</td>
                      <td>{notification.topic}</td>
                      <td>{new Date(notification.startDate).toLocaleString('ar-GB', {
                        weekday: 'long',
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true
                      })}</td>
                      <td>{new Date(notification.endDate).toLocaleString('ar-GB', {
                        weekday: 'long',
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true
                      })}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4">لا توجد تبليغات</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {isFormOpen && (
            <div className="edit-overlay">
              <div className="noti-form">
                <h2>إنشاء تبليغ جديد</h2>
                <label>
                  <span>الموضوع:</span>
                  <input
                    type="text"
                    name="topic"
                    value={notificationData.topic}
                    onChange={handleInputChange}
                  />
                </label>
                <label>
                  <span>التفاصيل:</span>
                  <textarea
                  className='notidetal'
                    name="details"
                    value={notificationData.details}
                    onChange={handleInputChange}
                  ></textarea>
                </label>
                <label>
                  <span>تاريخ البدء:</span>
                  <input
                    type="datetime-local"
                    name="startDate"
                    value={notificationData.startDate}
                    onChange={handleInputChange}
                  />
                </label>
                <label>
                  <span>تاريخ الانتهاء:</span>
                  <input
                    type="datetime-local"
                    name="endDate"
                    value={notificationData.endDate}
                    onChange={handleInputChange}
                  />
                </label>
                <label>
                  <span> الى:</span>
                  <Dropform
                                    options={recipientsList}
                                    selectedValues={notificationData.to}
                                    onChange={handleDropdownChange}
                                />
                </label>
                <div className="edit-actions">
                  <button className="save-button" onClick={handleFormSubmit}>إضافة</button>
                  <button className="cancel-button" onClick={() => setIsFormOpen(false)}>إلغاء</button>
                </div>
              </div>
            </div>
          )}
        </>
       
      ) : (
        
        <div className="selected-item-details">
           <div className="header2">
            <div className="header-content">
              <IoNotificationsSharp alt="Icon" className="header-icon" />
              <h1 className="header-text2">التبليغات</h1>
            </div>
            
            
          </div>
          <h2>تفاصيل التبليغ</h2>
          <div className="details-grid">
            <div className="grid-item">
              <label>المرسل:</label>
              <input type="text" value={selectedItem.owner.name} readOnly />
            </div>
            <div className="grid-item">
              <label>الموضوع:</label>
              <input type="text" value={selectedItem.topic} readOnly />
            </div>
            
            <div className="grid-item">
              <label>إلى:</label>
              <input type="text" value={selectedItem.to.join(', ')} readOnly />
            </div>
            <div className="grid-item">
              <label>تاريخ البدء:</label>
              <input type="text" value={new Date(selectedItem.startDate).toLocaleString('ar-GB', {
                weekday: 'long',
                day: 'numeric',
                month: 'long',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: true
              })} readOnly />
            </div>
            <div className="grid-item">
              <label>تاريخ الانتهاء:</label>
              <input type="text" value={new Date(selectedItem.endDate).toLocaleString('ar-GB', {
                weekday: 'long',
                day: 'numeric',
                month: 'long',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: true
              })} readOnly />
            </div>
            <div className="grid-item">
              <label>التفاصيل:</label>
              <textarea value={selectedItem.details} readOnly />
            </div>
          </div>

          <button onClick={() => setSelectedItem(null)} className="back-button">رجوع</button>
        </div>
      )}
    </div>
  );
};

export default Notification;
