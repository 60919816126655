import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ element, allowedDepartments, deniedDepartments }) => {
  const isAuthenticated = !!localStorage.getItem('token');
  const userDepartmentName = localStorage.getItem('departmentName');

  const hasAccess = allowedDepartments ? allowedDepartments.includes(userDepartmentName) : true;

  const deniedAccess = deniedDepartments ? deniedDepartments.includes(userDepartmentName) : false;

  return isAuthenticated && hasAccess && !deniedAccess ? element : <Navigate to="/" />;
};

export default ProtectedRoute;
