import React, { useState, useEffect, useCallback } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './dashboard.css';
import logoImage from '../images/login2.png'; 
import profileImage from '../images/profile.webp';
import { FaUsers } from "react-icons/fa6";
import { IoNotificationsSharp } from "react-icons/io5";
import { BsChatLeftTextFill } from "react-icons/bs";
import { LuFileClock, LuFileWarning, LuFileDown, LuUserCircle2 } from "react-icons/lu";
import { MdLogout } from 'react-icons/md';
import upImg from '../images/upload.svg';
import downImg from '../images/download.png';
import archImg from '../images/archive.png';

const Dashboard = () => {
    const [selectedOption, setSelectedOption] = useState("");
    const [employeeName, setEmployeeName] = useState('');
    const [employeeTitle, setEmployeeTitle] = useState('');
    const [counts, setCounts] = useState({
        income: 0,
        outbox: 0,
        archive: 0,
        notComplete: 0,
        delatory: 0,
    });

    const location = useLocation();
    const navigate = useNavigate();

    // Memoized handleChange for better performance
    const handleChange = useCallback((e) => {
        const value = e.target.value;
        setSelectedOption(value);
        const stateData = { employeeName, employeeTitle };
        if (value === 'internalMemo') {
            navigate('/internalMemo', { state: stateData });
        } else if (value === 'issuedBook') {
            navigate('/issuedBook', { state: stateData });
        }
    }, [employeeName, employeeTitle, navigate]);

    // Fetch employee details from localStorage
    useEffect(() => {
        const storedName = localStorage.getItem('employeeName');
        const storedTitle = localStorage.getItem('employeeTitle');
        if (storedName) setEmployeeName(storedName);
        if (storedTitle) setEmployeeTitle(storedTitle);
    }, []);

    // Fetch counts for different categories
    useEffect(() => {
        const fetchCounts = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/general/counts`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    },
                });
                const data = response.data;
                setCounts({
                    income: data.import,
                    outbox: data.export,
                    archive: data.archive,
                    notComplete: data.inProgress,
                    delatory: data.delatory,
                });
            } catch (error) {
                console.error('Error fetching counts:', error);
            }
        };
        fetchCounts();
    }, []);

    // Handle logout functionality
    const handleLogout = async () => {
        try {
            const token = localStorage.getItem('token'); 
            if (token) {
                await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/account/logout`, {}, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                localStorage.clear();
                navigate('/');
                window.location.reload();
            }
        } catch (error) {
            console.error('Error logging out:', error);
        }
    };

    const handleLinkClick = (targetPath) => {
        if (location.pathname === targetPath) window.location.reload();
    };

    // Reusable Link component to reduce repetition
    const MenuLink = ({ to, icon, label, count }) => (
        <li>
            <Link 
                to={to} 
                className={`menu-link ${location.pathname === `/dashboard/${to}` ? 'active' : ''}`}
                onClick={() => handleLinkClick(`/dashboard/${to}`)}
            >
                {icon}
                {label}
                {count !== undefined && <span className="menu-count">{count}</span>}
            </Link>
        </li>
    );

    return (
        <div className="dashboard-container">
            <div className="top-bar">
                <div className="logo-container">
                    <img src={logoImage} alt="Logo" className="logo" />
                </div>
                <div className="user-profile">
                    <img src={profileImage} alt="User Profile" className="profile-pic" />
                    <div className="user-info">
                        <p>{employeeName || 'الاسم الثلاثي'}</p>
                        <span className="profiletext">{employeeTitle || 'موظف ادخال البيانات'}</span>
                    </div>
                </div>
            </div>

            <div className="main-content">
                <div className="side-menu">
                    <div className="memo-select-container">
                        <select 
                            id="memo-select" 
                            value={selectedOption} 
                            onChange={handleChange} 
                            className="memo-select"
                        >
                            <option value="" disabled hidden>انشاء مذكرة</option>
                            <option value="internalMemo">إنشاء مذكرة داخلية</option>
                            <option value="issuedBook">إنشاء كتاب صادر</option>
                        </select>
                    </div><br></br>
                    <ul className="menu-list">
                        <MenuLink to="income" icon={<img src={downImg} alt="Icon" className="menu-icon" />} label="الوارد" count={counts.income} />
                        <MenuLink to="outbox" icon={<img src={upImg} alt="Icon" className="menu-icon" />} label="الصادر" count={counts.outbox} />
                        <MenuLink to="archive" icon={<img src={archImg} alt="Icon" className="menu-icon" />} label="الأرشيف" count={counts.archive} />
                        <MenuLink to="notcomplete" icon={<LuFileClock className="menu-icon" />} label="قيد الانجاز" count={counts.notComplete} />
                        <MenuLink to="delatory" icon={<LuFileWarning className="menu-icon" />} label="المتلكئ" count={counts.delatory} />
                        <MenuLink to="notification" icon={<IoNotificationsSharp className="menu-icon" />} label="التبليغات" />
                        <MenuLink to="chat" icon={<BsChatLeftTextFill className="menu-icon" />} label="المحادثات" />
                        <MenuLink to="users" icon={<FaUsers className="menu-icon" />} label="المستخدمين" />
                        <MenuLink to="userinfo" icon={<LuUserCircle2 className="menu-icon" />} label="الحساب الشخصي" />
                        <li>
                            <Link to="/" onClick={handleLogout} className="menu-link">
                                <MdLogout className="menu-icon" />
                                تسجيل الخروج
                            </Link>
                        </li>
                    </ul>
                </div>

                <div className="content-wrapper">
                    <Outlet />
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
