import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './internalMemo.css';
import logoImage from '../images/login2.png'; 
import Memo from '../images/Memo.svg'; 
import print from '../images/printer.svg';
import fileImg from '../images/file.png';


import profileImage from '../images/profile.webp';

const InternalMemo = () => {
  const navigate = useNavigate();
  const routerLocation = useLocation(); 
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [selectedRecipients, setSelectedRecipients] = useState([]);
  const [recipientsList, setRecipientsList] = useState([]);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false); // State for success message/modal
  const [isSubmitting, setIsSubmitting] = useState(false); // New state to track submission


  // State for input fields
  const [topic, setTopic] = useState('');
  const [number, setNumber] = useState('');
  const [date, setDate] = useState('');
  const [paragraph, setParagraph] = useState('');

  const employeeName = routerLocation.state?.employeeName || '';
  const employeeTitle = routerLocation.state?.employeeTitle || '';


  const [isFileValid, setIsFileValid] = useState(true);
  const [fileChosen, setFileChosen] = useState(false);
  const [formFile, setformFile] = useState(null);




  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/mail/forwarding-options`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        // Extract names from response data
        const extractNames = (data) => {
          const departmentNames = data.departments.map(department => department.name);
          const sectionNames = data.sections.map(section => section.name);
          const employeeNames = data.employees.map(employee => employee.name);
          return [...departmentNames, ...sectionNames, ...employeeNames];
        };

        const names = extractNames(response.data);
        setRecipientsList(names);
      } catch (err) {
        setError('Failed to fetch data. Please try again.');
        console.error('Fetch error:', err);
      }
    };

    fetchData();
  }, []);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedRecipients([...selectedRecipients, value]);
    } else {
      setSelectedRecipients(selectedRecipients.filter((recipient) => recipient !== value));
    }
    setError(''); // Clear error when checkbox changes
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent form from reloading the page
    setIsSubmitting(true); // Disable the button


    // Create form data to send
    const formData = new FormData();
    formData.append('To', selectedRecipients.join(', ')); // Add recipients as a comma-separated string
    formData.append('Topic', topic);
    formData.append('Number', 0);
    formData.append('Date', "لا يوجد");
    formData.append('Paragraph', paragraph);
    formData.append('DocumentUrl', "test");
    formData.append('MailType', 2);
    formData.append('CreatorUserName', 'test');
    formData.append('formFile', formFile);



    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/forms/create-internal-mail`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        }
      });

      if (response.data.id !== null) {
        setSuccess(true); // Show success message or modal
        setError(''); // Clear any previous error
      }
    } catch (error) {
      console.error('Error submitting the form:', error);
      setError('Failed to submit. Please try again.');
    }
    finally {
      setIsSubmitting(false); // Re-enable the button after the process completes
    }
  };



  const handleInputChange = (setter) => (event) => {
    setter(event.target.value);
    setError('');  // Clear the error message when the user starts typing
    setSuccess(false);  // Clear the success message when the user starts typing
  };


  const handleBackClick = () => {
    navigate(-1); // This navigates back to the previous page
  };



  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFileChosen(true);

    if (selectedFile) {
        const validTypes = [
            'application/pdf',
            'application/msword', // .doc
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
            'application/vnd.ms-excel', // .xls
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' // .xlsx
        ];

        if (validTypes.includes(selectedFile.type)) {
            setformFile(selectedFile);
            setIsFileValid(true);
        } else {
            setformFile(null);
            setIsFileValid(false);
        }
    } else {
        setformFile(null);
        setIsFileValid(false);
    }
};



  

  return (
    <div className="internalM-container">
      <div className="top-bar">
        <div className="logo-container">
          <img src={logoImage} alt="Logo" className="logo" />
        </div>
        <div className="user-profile">
          <img src={profileImage} alt="User Profile" className="profile-pic" />
          <div className="user-info">
            <p>{employeeName || 'الاسم الثلاثي'}</p>
            <span className="profiletext">{employeeTitle || 'موظف ادخال البيانات'}</span>
          </div>
        </div>
      </div>
      <div className="document-content">
        <h3>عرض توضيحي</h3>
        <img src={Memo} alt="Logo" className="Memo" />
        
        <div className="text-overlay">
          <h5 className='topicover'> م/{topic}</h5>
          <p className='numover'> {number}</p>
          <p className='dateover'> {date}</p>
          <textarea className="noteover" value={paragraph} readOnly />
          </div>
      </div>
      
      <form className="memo-form" onSubmit={handleSubmit}>
  <h2>انشاء مذكرة داخلية</h2>
  <div className="formM-group">
      <label className="labelM">الى</label>
      <div className="multi-select-dropdown1">
        <button type="button" className="dropdown-toggle1" onClick={toggleDropdown}>
          {/* Display selected recipients or default placeholder */}
          {selectedRecipients.length > 0 
            ? selectedRecipients.join(', ') 
            : 'اختر من القائمة...'}
          <svg className={`arrow-icon1 ${isDropdownOpen ? 'open1' : ''}`} width="15" height="10" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 10l5 5 5-5H7z" fill="#000" />
          </svg>
        </button>
        {isDropdownOpen && (
          <div className="dropdown-menu1">
            {recipientsList.map((recipient, index) => (
              <label key={index} className="dropdown-item1">
                <input
                  type="checkbox"
                  value={recipient}
                  checked={selectedRecipients.includes(recipient)}
                  onChange={handleCheckboxChange}
                />
                {recipient}
              </label>
            ))}
          </div>
        )}
      </div>
    </div>
  <div className="formM-group">
    <label className="labelM">الموضوع</label>
    <input
      type="text"
      value={topic}
      onChange={handleInputChange(setTopic)}
      required
    />
  </div>
 

  <div className="formM-group">
    <label className="labelM">النص</label>
    <textarea
      placeholder="اكتب شيئاً ..."
      value={paragraph}
      onChange={handleInputChange(setParagraph)}
      dir="rtl"
      required
    ></textarea>
  </div>
  <div className="Memo-buttons">
  <button type="submit" className="send-button" disabled={isSubmitting}>
            {isSubmitting ? 'جارٍ الإرسال...' : 'ارسال'}
          </button>
    
                            <label
                                htmlFor="file-upload"
                                className={`sendfile-button ${fileChosen ? (isFileValid ? 'valid-border' : 'invalid-border') : ''}`}
                            >
                                <img src={fileImg} alt="Upload" />
                                {fileChosen ? 'تم ارفاق الاوليات' : 'إرفاق الاوليات'}
                            </label>
                            <input
                                id="file-upload"
                                type="file"
                                onChange={handleFileChange}
                                accept=".pdf, .doc, .docx, .xls, .xlsx"
                                className="file-input"
                            />
   
    <button className="send-button" onClick={handleBackClick}>الـــــــرجوع</button>
  </div>
  {error && <p className="error-message">{error}</p>}
  {success && <div className="error1-message">تم إرسال المذكرة بنجاح!</div>}
</form>

    </div>
  );
}

export default InternalMemo;
