import React, { useEffect, useState } from 'react';
import axios from 'axios';

import './income.css';
import downImg from '../images/download.png';
import blue from '../images/blue.svg';
import normal from '../images/normal.svg';
import purple from '../images/purple.svg';
import red from '../images/red.svg';
import secret from '../images/secret.svg';
import recycle from '../images/recycle-bin.svg';
import pencil from '../images/pencil.svg';
import send from '../images/send.svg';
import Delete from '../images/delete.svg';
import income from '../images/income.svg';
import archImg from '../images/archive.png';
import { LuFileDown } from "react-icons/lu";


import MultiSelectDropdown from './MultiSelectDropdown';
import Card from './card';



const Income = () => {
    const [selectedItem, setSelectedItem] = useState(null);
    const [departmentName, setDepartmentName] = useState('');
    const [searchTerm, setSearchTerm] = useState(''); // State to track search input
    const [isEditing, setIsEditing] = useState(false);
    const [isNumDate, setIsNumDate] = useState(false);
    const [data, setData] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false); // New state to track submission
    const [pdfUrls, setPdfUrls] = useState([]); // Updated to hold multiple PDFs
    const [currentPage, setCurrentPage] = useState(0);
    const [selectedRecipients, setSelectedRecipients] = useState([]);
  const [recipientsList, setRecipientsList] = useState([]);
    const [error, setError] = useState('');
    const [isForwarding, setIsForwarding] = useState(false);
    const [editData, setEditData] = useState({
        sender: '',
        topic: '',
        date: '',
        number: '',
        priorityMap: '',

    });

    const [numDate, setNumData] = useState({
        date: '',
        num: '',

    });

    const [forwardData, setForwardData] = useState({
        formId: selectedItem ? selectedItem.id : '', // Assuming selectedItem has id
        note: '',
        recipients: []
    });
    


    useEffect(() => {
        // Retrieve the department name from localStorage
        const storedDepartmentName = localStorage.getItem('departmentName');
        setDepartmentName(storedDepartmentName);
      }, []);


    useEffect(() => {
        const fetchData = async () => {
          try {
            
            const token = localStorage.getItem('token');
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/mail/forwarding-options`, {
              headers: {
                'Authorization': `Bearer ${token}`
              }
            });
            const extractNames = (data) => {
              const departmentNames = data.departments.map(department => department.name);
              const sectionNames = data.sections.map(section => section.name);
              const employeeNames = data.employees.map(employee => employee.name);
              return [...departmentNames, ...sectionNames, ...employeeNames];
            };
    
            const names = extractNames(response.data);
            setRecipientsList(names);
          } catch (err) {
            setError('Failed to fetch data. Please try again.');
            console.error('Fetch error:', err);
          }
        };
    
        fetchData();
      }, []);
    

    const handleDropdownChange = (selectedValues) => {
        setForwardData(prevState => ({
            ...prevState,
            recipients: selectedValues
        }));
    };
    

    const handleRowClick = (item) => {
        setSelectedItem(item);
        setForwardData(prevState => ({
            ...prevState,
            formId: item.id 
        }));
    };
    

    const handleBackClick = () => {
        setSelectedItem(null);
    };

   

    const handleForwardClick = () => {
        setIsForwarding(true);
    };

    const handleEditClick = () => {
        if (selectedItem) {
            setEditData({
                sender: selectedItem.sender || '',
                topic: selectedItem.topic || '',
                number: selectedItem.number ? parseInt(selectedItem.number, 10) : '',          
                      date: selectedItem.date || '',
                priority: selectedItem.priority || ''
            });
            setIsEditing(true);
        }
    };

    const handleAddnumdateClick = () => {
        if (selectedItem) {
            setNumData({
                num: selectedItem.num ? parseInt(selectedItem.num, 10) : '',          
                      date: selectedItem.date || '',
            });
            setIsNumDate(true);
        }
    };






    const handleEditChange = (e) => {
        setEditData({ ...editData, [e.target.name]: e.target.value });
    };

    const handleNumDateChange = (e) => {
        const { name, value } = e.target;
        setNumData((prevData) => ({
            ...prevData,
            [name]: value, 
        }));
    };
    



    const handleCheckboxChange = (e) => {
        const value = e.target.value;
        setForwardData(prevState => {
          const updatedRecipients = e.target.checked
            ? [...prevState.recipients, value]
            : prevState.recipients.filter(recipient => recipient !== value);
      
          return { ...prevState, recipients: updatedRecipients };
        });
      };
      
      const handleForwardChange = (e) => {
        setForwardData(prevState => ({
          ...prevState,
          note: e.target.value
        }));
      };


    const handleForwardSubmit = async () => {
        setIsSubmitting(true); // Disable the button
        try {
            

            const token = localStorage.getItem('token');
            await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/mail/forward-mail`, forwardData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            alert('تم تحويل الايميل بنجاح !');
            setIsForwarding(false); 
            window.location.reload();
        } catch (err) {
            setError('Failed to forward mail. Please try again.');
            console.error('Forward error:', err);
        }
        finally {
            setIsSubmitting(false); // Re-enable the button after the process completes
          }
    };
    const handleSaveClick = async () => {
        try {
            // Convert priority to English and ensure number is an integer
            const editDataWithEnglishPriority = {
                ...editData,
                priority: getPriorityInEnglish(editData.priority),
                number: parseInt(editData.number, 10), // Convert number to integer before sending
            };
    
            console.log(editDataWithEnglishPriority);
    
            const token = localStorage.getItem('token');
            await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/forms/${selectedItem.id}`, editDataWithEnglishPriority, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
            alert('Data updated successfully!');
            setIsEditing(false);

            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/forms/${selectedItem.id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
    
            // Update selectedItem with the new data
            setSelectedItem(response.data);





        } catch (err) {
            setError('Failed to update data. Please try again.');
            console.error('Update error:', err);
        }
    };


    const handleSaveNumDateClick = async () => {
        const token = localStorage.getItem('token');
    
        try {
            const formData = new FormData();
            formData.append('num', numDate.num);
            formData.append('date', numDate.date);
            
    
            const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/forms/addNumDate/${selectedItem.id}`, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,   
                    'Content-Type': 'application/json-patch+json',   
                     
                },
            });
           
    
            alert('تم حفظ البيانات بنجاح!');
            setIsNumDate(false);
        } catch (error) {
            console.error('خطأ في حفظ البيانات:', error);
            alert('حدث خطأ أثناء حفظ البيانات.');
        }
    };










    



    const handleCancelClick= () => {
        // Reset editData (you can replace this with your preferred method)
        setEditData({
            sender: '',
            topic: '',
            number: '',
            date: '',
            priority: ''
        });
        setForwardData({
            recipients: [],
            note: '',
        });

        setNumData({
            num: '',
            date: '',
        })
    
        // Close the editing form
        setIsEditing(false);
        setIsForwarding(false);
        setIsNumDate(false);
    }


    const getStatusIcon = (priority) => {
        switch (priority) {
            case 'سري':
                return blue;
            case 'عادي':
                return normal;
            case 'عاجل':
                return purple;
            case 'عاجل جدا':
                return red;
            case 'سري وشخصي':
                return secret;
            default:
                return null;
        }
    };


    const priorityMap = {
        'Secret': 'سري',
        'Normal': 'عادي',
        'Urgent': 'عاجل',
        'VeryUrgent': 'عاجل جدا',
        'PersonalSecret': 'سري وشخصي'
    };







    const getPriorityInEnglish = (priority) => {
        const priorityMapReversed = Object.fromEntries(
            Object.entries(priorityMap).map(([key, value]) => [value, key])
        );
        return priorityMapReversed[priority] || priority;
    };
    




    
       
    
        useEffect(() => {
            const fetchData = async () => {
                try {
                    const token = localStorage.getItem('token');
                    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/forms`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setData(response.data);
                } catch (err) {
                    setError('Failed to fetch data. Please try again.');
                    console.error('Fetch error:', err);
                }
            };
    
            fetchData();
        }, []);
    
        const getPriorityInArabic = (priority) => priorityMap[priority] || priority;




        useEffect(() => {
            const fetchPdf = async () => {
                if (selectedItem) {
                    try {
                        const token = localStorage.getItem('token');
                        let fileResponse;
                        console.log(selectedItem.documentUrl);
    
                        // Fetch from the document URL endpoint
                        if (selectedItem.documentUrl) {
                            fileResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/forms/file?fileUrl=${selectedItem.documentUrl}`, {
                                headers: {
                                    'Authorization': `Bearer ${token}`,
                                    'Content-Type': 'application/pdf',
                                },
                                responseType: 'blob',
                            });
                        }
    
                        // Create URLs for the available response
                        const urls = [];
                        if (fileResponse) {
                            const fileUrl = URL.createObjectURL(new Blob([fileResponse.data], { type: 'application/pdf' }));
                            urls.push(fileUrl);
                        }
    
                        // Set PDF URLs
                        setPdfUrls(urls);
                    } catch (err) {
                        console.error('Error fetching PDF:', err);
                    }
                }
            };
    
            fetchPdf();
        }, [selectedItem]);
    
        const handleNext = () => {
            if (currentPage < pdfUrls.length - 1) {
                setCurrentPage(currentPage + 1);
            }
        };
    
        const handlePrevious = () => {
            if (currentPage > 0) {
                setCurrentPage(currentPage - 1);
            }
        };
        



    const handleArchive = async () => {
        if (selectedItem) {
            try {
                const token = localStorage.getItem('token');
                await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/forms/archive/${selectedItem.id}`, {}, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });
                alert('Form archived successfully!');
                setSelectedItem(null);
                // Optionally, refresh data to reflect changes
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/forms`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setData(response.data);
            } catch (err) {
                setError('Failed to archive form. Please try again.');
                console.error('Archive error:', err);
            }
        }
    };

    const handleDelete = async () => {
        if (selectedItem) {
            try {
                const token = localStorage.getItem('token');
                await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/forms/${selectedItem.id}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });
                alert('Form deleted successfully!');
                setSelectedItem(null);
                // Optionally, refresh data to reflect changes
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/forms`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setData(response.data);
            } catch (err) {
                setError('Failed to delete form. Please try again.');
                console.error('Delete error:', err);
            }
        }
    };

    const priorityOptions = [
        { value: 'Secret', label: 'سري' },
        { value: 'Normal', label: 'عادي' },
        { value: 'Urgent', label: 'عاجل' },
        { value: 'VeryUrgent', label: 'عاجل جدا' },
        { value: 'PersonalSecret', label: 'سري وشخصي' }
    ];



    const addSuggestion = (suggestion) => {
        setForwardData({
            ...forwardData,
            note: forwardData.note + ' ' + suggestion, // Add the suggestion to the existing text
        });
    };



    // Filter data based on search term
    const filteredData = data.filter((item) => {
        const searchQuery = searchTerm.toLowerCase();
        const itemPriority = (getPriorityInArabic(item.priority)).toLowerCase();
        const itemNumber = String(item.number).toLowerCase(); // Ensure item.number is a string
        const itemSender = item.sender.toLowerCase();
        const itemTopic = item.topic.toLowerCase();
        const itemDate = item.date.toLowerCase();

        return (
            itemPriority.includes(searchQuery) ||
            itemNumber.includes(searchQuery) ||
            itemSender.includes(searchQuery) ||
            itemTopic.includes(searchQuery) ||
            itemDate.includes(searchQuery)
        );
    });





    




    return (
        <div className="income-container">
            {/* Conditional Rendering: Table or Detail View */}
            {selectedItem ? (
                <div className="detail-container">
                  <div className="header2">
    <div className="header-content">
    <img src={income}
                    className="header-icon" />
        <h1 className="header-text2">الــــــــوارد</h1>
    </div>
    <div className="actions1">
   
        <button className="delete-button" onClick={handleDelete}>
            <img src={recycle} alt="delete" className="button-icon" />
            حذف
        </button>
        {departmentName === 'قسم الارشيف' && (
        <button className="edit-button" onClick={handleArchive}>
          <img src={archImg} alt="archive" className="button-icon" />
          ارشفة
        </button>
      )}

{departmentName === 'قسم الارشيف' && (
        <button className="editnum-button" onClick={handleAddnumdateClick}>
          <img src={archImg} alt="archive" className="button-icon" />
          اضافة عدد وتاريخ
        </button>
      )}

        <button className="edit-button" onClick={handleEditClick}>
            <img src={pencil} alt="Edit" className="button-icon" />
            تعديل
        </button>
       
    </div>
</div>
                    <div className="detail-content">
                        <div className="detail-info">
                            <img src={getStatusIcon(getPriorityInArabic(selectedItem.priority))} alt={selectedItem.priority} className="status-icon-large" />
                            <span className='statuswithflag'>{getPriorityInArabic(selectedItem.priority)}</span>
    <p><span className="detail-title">المرسل:</span> <span className="detail-answer">{selectedItem.sender}</span></p>
    <p><span className="detail-title">الموضوع:</span> <span className="detail-answer">{selectedItem.topic}</span></p>
    <p><span className="detail-title">العدد:</span> <span className="detail-answer">{selectedItem.number}</span></p>
    <p><span className="detail-title">التاريخ:</span> <span className="detail-answer">{selectedItem.date}</span></p>
    
    
    
                        </div>
                     <div className='pdf'>
                {pdfUrls.length > 0 ? (
                    <iframe src={pdfUrls[currentPage]} width="100%" height="500px" />
                    
                ) : (
                    <p>Loading PDF...</p>
                )}
                <div className='pdfurl'>
                
                {/* <button 
        className={`pdfurlB ${currentPage === 0 ? 'selected' : ''}`} 
        onClick={handlePrevious} 
        disabled={currentPage === 0}
    >
        Previous
    </button>
    <button 
        className={`pdfurlB ${currentPage === pdfUrls.length - 1 ? 'selected' : ''}`} 
        onClick={handleNext} 
        disabled={currentPage === pdfUrls.length - 1}
    >
        Next
    </button> */}
                </div>
            </div> <br></br>
                            
                         <div className='line'></div>
                        
                        <div className="actions2">
                           
                           
                        
                        <button className="back-button" onClick={handleBackClick}>الـــــــرجوع</button>

                        <button className="forward-button" onClick={handleForwardClick}><img src={send} alt="forward" className="forward-icon" />
                        تهميش و تحويل</button>
                        </div>
                    </div>

                    <Card formId={selectedItem.id}/>
                     {/* Edit Overlay */}
                     {isEditing && (
                        <div className="edit-overlay">
                            <div className="edit-form">
                                <h2>تعديل البيانات</h2>
                                <label>
                                    <span>المرسل:</span>
                                    <input
                                        type="text"
                                        name="sender"
                                        value={editData.sender}
                                        onChange={handleEditChange}
                                    />
                                </label>
                                <label>
                                    <span>الموضوع:</span>
                                    <input
                                        type="text"
                                        name="topic"
                                        value={editData.topic}
                                        onChange={handleEditChange}
                                    />
                                </label>
                                <label>
                                    <span>العدد:</span>
                                    <input
                                        type="text"
                                        name="number"
                                        value={editData.number}
                                        onChange={handleEditChange}
                                    />
                                </label>
                                <label>
                                    <span>التاريخ:</span>
                                    <input
                                        type="text"
                                        name="date"
                                        value={editData.date}
                                        onChange={handleEditChange}
                                    />
                                </label>
                                <label>
                <span>الحالة:</span>
                <select
                    name="priority"
                    
                    value={editData.priority}
                    onChange={handleEditChange}
                >
                    {priorityOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
                </select>
            </label>
                                <div className="edit-actions">
                                    <button className="save-button" onClick={handleSaveClick}>حفظ</button>
                                    <button className="cancel-button" onClick={handleCancelClick}>إلغاء</button>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* addNumDate */}

                    {isNumDate && (
                        <div className="edit-overlay">
                            <div className="numdate-form">
                                <h2> اضافة عدد وتاريخ</h2>
                                <label>
                                    <span>العدد:</span>
                                    <input
                                        type="text"
                                        name="num"
                                        value={numDate.num}
                                        onChange={handleNumDateChange}
                                    />
                                </label>
                                <label>
                                    <span>التاريخ:</span>
                                    <input
                                        type="text"
                                        name="date"
                                        value={numDate.date}
                                        onChange={handleNumDateChange}
                                    />
                                </label>
                                <div className="edit-actions">
                                    <button className="save-button" onClick={handleSaveNumDateClick}>حفظ</button>
                                    <button className="cancel-button" onClick={handleCancelClick}>إلغاء</button>
                                </div>
                            </div>
                        </div>
                    )}









                 {/* Forward Overlay */}
                 {isForwarding && (
                        <div className="edit-overlay">
                            <div className="forward-form">
                            <img src={Delete} alt="Icon" className="delete-icon" onClick={handleCancelClick} />
                            <div className='boxf'>
                            <label>تحويل الى</label>
                            <MultiSelectDropdown
                                    options={recipientsList}
                                    selectedValues={forwardData.recipients}
                                    onChange={handleDropdownChange}
                                />
                                
                                    <br></br><br></br>
                                
                                <label>
                                    <span>هامش</span>
                                    <div className="suggestion-bubbles">
        <span onClick={() => addSuggestion('اجراء اللازم لطفا')}>اجراء اللازم لطفا</span>
        <span onClick={() => addSuggestion('مداولة')}>مداولة</span>
        <span onClick={() => addSuggestion('حفظ / ارشفة')}>حفظ / ارشفة</span>
    </div>
                                    <textarea
                                    placeholder='       اكتب شيئاً ...'
                                        name="note"
                                        value={forwardData.note}
                                        onChange={handleForwardChange}
                                    />
                                </label>
                              
                                </div>
                                <div className="edit-actions2">
                                    <button className="done-button" onClick={handleForwardSubmit} disabled={isSubmitting}>
                                        {isSubmitting ? 'جار التحويل...' : 'اتمام' }
                                    </button>
                                   
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            ) : (
            
               <>
                    {/* Header */}
                    <div className="header">
                    <img src={income} 
                    className="header-icon" />
                        <h1 className="header-text">الــــــــوارد</h1>
                    </div>
                    
                    {/* Search Bar */}
                    <div className="search-bar">
                        <button className="search-button">
                            <svg width="20" height="20" viewBox="0 0 24 24">
                                <path d="M9.5 3c-3.6 0-6.5 2.9-6.5 6.5s2.9 6.5 6.5 6.5c1.4 0 2.7-.4 3.8-1.2l5.7 5.7 1.4-1.4-5.7-5.7c.8-1.1 1.2-2.4 1.2-3.8 0-3.6-2.9-6.5-6.5-6.5zm0 2c2.5 0 4.5 2 4.5 4.5s-2 4.5-4.5 4.5-4.5-2-4.5-4.5 2-4.5 4.5-4.5z"/>
                            </svg>
                        </button>
                        <input
                            type="text"
                            placeholder="بحث"
                            className="search-input"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)} // Update search term on input change
                        />
                        <button className="filter-button">
                            <svg width="20" height="20" viewBox="0 0 24 24">
                                <path d="M3 4c-1.1 0-1.99.9-1.99 2l-.01 2c0 .71.37 1.36.96 1.74l5.49 3.26v8.5c0 .38.21.72.55.88.14.06.28.12.43.12.24 0 .48-.07.68-.22l3-2c.26-.18.42-.47.42-.78v-6.5l5.48-3.26c.6-.38.97-1.02.97-1.74v-2c0-1.1-.9-2-2-2h-16zm0 2h16v2l-6 3.57v6.93l-2 1.33v-8.26l-6-3.57v-2z"/>
                            </svg>
                        </button>
                    </div>

                    {/* Table */}
                    <table className="income-table">
                        <tbody>
                            {filteredData.map(item => (
                                <tr 
                                    key={item.id} 
                                    onClick={() => handleRowClick(item)} 
                                    className="clickable-row"
                                >
                                    <td className="status-cell">
                                    <img src={getStatusIcon(getPriorityInArabic(item.priority))} alt="Status Icon" className='status-icon' /> 
                                    <span>{getPriorityInArabic(item.priority)}</span>
                                    </td>
                                    <td className="sender-cell">{item.sender}</td>
                                    <td className="subject-cell">{item.topic}</td>
                                    <td className="section-cell">
                                        <div className="section-badge">{item.note}</div>
                                    </td>
                                    <td className="date-cell">{item.date}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    
                </>
            )}
        </div>
    );
};

export default Income;