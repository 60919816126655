import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './users.css';
import pencil from '../images/pencil.svg';
import recycle from '../images/recycle-bin.svg';
import { FaUsers } from "react-icons/fa6";

const Users = () => {
    const [data, setData] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [isAdding, setIsAdding] = useState(false);
    const [departments, setDepartments] = useState([]); // State to store department names
    const [canSeeArchive, setCanSeeArchive] = useState(false); // Initialize it as false (or true if needed)
    const [editData, setEditData] = useState({
        name: '',
        username: '',
        email: '',
        title: '',
        departmentName: '',
        password : '',
        position: 'HeadOfDepartment',
    });

    const [addData, setAddData] = useState({
        name: '',
        username: '',
        password: '',
        email: '',
        title: '',
        departmentName: '',
        Position: 'HeadOfDepartment'
    });


    const fetchData = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/account`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setData(response.data); // Update state with the fetched data
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);



    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [selectedDepartments, setSelectedDepartments] = useState([]);
    const handleRowClick = (item) => {
        setSelectedItem(item);
        setIsAdding(false); // Close the add form when selecting a user
    };

    const handleEditClick = () => {
        

        setEditData(selectedItem);
        setIsEditing(true);
    };

    const handleAddClick = () => {
        setIsAdding(true);
        setSelectedItem(null); // Ensure no item is selected when adding a new user
    };

    const handleDeleteClick = async (userName) => {
        try {
            const token = localStorage.getItem('token');
            await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/account/remove/${userName}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
    
            // Alert on successful deletion
            alert('User deleted successfully.');
            window.location.reload();
            
            fetchData(); 
    
        } catch (error) {
            console.error('Error deleting item:', error);
            // Alert or notify the user about the error
            alert('Failed to delete the user. Please try again.');
        }
    };
    

    const handleEditChange = (e) => {
        setEditData({
            ...editData,
            [e.target.name]: e.target.value
        });
    };

 


    const handleSaveClick = async () => {
        // Create FormData object
        const formData = new FormData();
        
        if (isEditing) {
            // For editing an existing user
            formData.append('username', editData.username);
            formData.append('email', editData.email);
            formData.append('password', editData.password);
            formData.append('name', editData.name);
            formData.append('title', editData.title);
            formData.append('departmentName', editData.departmentName);
            formData.append('Position', editData.position);

        } else if (isAdding) {
            // For adding a new user
            formData.append('username', addData.username);
            formData.append('email', addData.email);
            formData.append('password', addData.password);
            formData.append('name', addData.name);
            formData.append('title', addData.title);
            formData.append('departmentName', addData.departmentName);
            formData.append('Position', addData.Position);
        }
    
       
        for (let pair of formData.entries()) {
            console.log(`${pair[0]}: ${pair[1]}`);
        }
    
        try {
            let response;
            if (isEditing) {
                
                response = await axios.put(
                    `${process.env.REACT_APP_API_BASE_URL}/api/account/update-user/${editData.username}`, 
                    formData,
                    {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`,
                            'Content-Type': 'application/json',
                        },
                    }
                );
                console.log('User updated successfully:', response.data);
            } else if (isAdding) {
    
                response = await axios.post(
                    `${process.env.REACT_APP_API_BASE_URL}/api/account/register`,
                    formData,
                    {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`,
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );
                
            
                fetchData(); 
                console.log('User added successfully:', response.data);
            }
        
            // Update local state and close form
            if (isEditing) {
                setData((prevData) =>
                    prevData.map((item) =>
                        item.username === editData.username ? { ...editData, canSeeArchive: item.canSeeArchive } : item
                    )
                );
                setIsEditing(false);
            } else if (isAdding) {
                setData([...data, response.data]); // Assuming response.data is the new user data
                setIsAdding(false);
            }
        } catch (error) {
            console.error('Error saving user:', error);
            // Handle error appropriately
        }
    };
    
    
    
    

    const handleCancelClick = () => {
        setIsEditing(false);
        setIsAdding(false);
    };

    const handleBackClick = () => {
        setSelectedItem(null);
    };


    const recipientsList = [
        'Recipient 1',
        'Recipient 2',
        'Recipient 3',
        'Recipient 4',
        'Recipient 5',
      ];
    
     
    
      const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };
    
const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;

    setAddData((prevState) => {
        const updatedDepartments = checked
            ? [...prevState.departmentName, value]
            : prevState.departmentName.filter(dept => dept !== value);
        return { ...prevState, departmentName: updatedDepartments };
    });
};




      const handleCheckboxChange2 = async (e, username) => {
        const canSeeArchiveStatus = e.target.checked; // Get the new state from the event
      
        // Update the local state for the specific user
        setData((prevData) =>
          prevData.map((item) =>
            item.username === username ? { ...item, canSeeArchive: canSeeArchiveStatus } : item
          )
        );
      
        const formData = new FormData();
        formData.append('CanSeeArchive', canSeeArchiveStatus); 
        try {
          const response = await axios.put(
            `${process.env.REACT_APP_API_BASE_URL}/api/account/changeSeeArchive/${username}`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'multipart/form-data',
              },
            }
          );
          console.log('Checkbox status updated:', response.data);
        } catch (error) {
          console.error('Error updating checkbox status:', error);
        }
      };
     

      useEffect(() => {
        const fetchDepartments = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_BASE_URL}/api/general/getdepartments`,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('token')}`,
                        },
                    }
                );
                const departmentNames = response.data.map(dept => dept.name); // Extract department names
                setDepartments(departmentNames); // Store in state
            } catch (error) {
                console.error('Error fetching departments:', error);
            }
        };
    
        fetchDepartments();
    }, []);
    

    const handleAddChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (name === "departmentName" && type === "checkbox") {
            setAddData(prevState => {
                const updatedDepartments = checked
                    ? [...prevState.departmentName, value]
                    : prevState.departmentName.filter(dept => dept !== value);
                return { ...prevState, departmentName: updatedDepartments };
            });
        } else {
            setAddData({
                ...addData,
                [name]: value
            });
        }
    };
    






    return (
        <>
            {selectedItem ? (
                <div className="detail-container">
                    <div className="header2">
                        <div className="header-content">
                            <FaUsers alt="Icon" className="header-icon" />
                            <h1 className="header-text2">المستخدمين</h1>
                        </div>
                        <div className="actions1">
                            <button className="delete-button" onClick={() => handleDeleteClick(selectedItem.username)}>
                                <img src={recycle} alt="delete" className="button-icon" />
                                حذف
                            </button>
                            <button className="edit-button" onClick={handleEditClick}>
                                <img src={pencil} alt="Edit" className="button-icon" />
                                تعديل
                            </button>
                        </div>
                    </div>
                    <div className="detail-content">
                        <div className="detail-info">
                        <p className="user-info-item"><strong className="user-info-label">الاسم :</strong> <span className="user-info-value">{selectedItem.name}</span></p>
                        <p className="user-info-item"><strong className="user-info-label">اسم المستخدم : </strong> <span className="user-info-value">{selectedItem.username}</span></p>
                        <p className="user-info-item"><strong className="user-info-label">البريد الالكتروني :</strong> <span className="user-info-value">{selectedItem.email}</span></p>
                        <p className="user-info-item"><strong className="user-info-label">العنوان الوظيفي :</strong> <span className="user-info-value">{selectedItem.title}</span></p>
                        <p className="user-info-item"><strong className="user-info-label">القسم :</strong> <span className="user-info-value">{selectedItem.departmentName}</span></p>
                        </div>
                        <div className="actions2">
                            <button className="back-button" onClick={handleBackClick}>الـــــــرجوع</button>
                        </div>
                    </div>
                    {isEditing && (
                        <div className="edit-overlay">
                            <div className="edit2-form">
                                <h2>تعديل البيانات</h2>
                                <label>
                                    <span>الاسم:</span>
                                    <input
                                        type="text"
                                        name="name"
                                        value={editData.name}
                                        onChange={handleEditChange}
                                    />
                                </label>
                                <label>
                                    <span> اسم المستخدم:</span>
                                    <input
                                        type="text"
                                        name="username"
                                        value={editData.username}
                                        onChange={handleEditChange}
                                    />
                                </label>
                                <label>
                                    <span>البريد الإلكتروني:</span>
                                    <input
                                        type="text"
                                        name="email"
                                        value={editData.email}
                                        onChange={handleEditChange}
                                    />
                                </label>
                                <label>
                                    <span>   كلمة المرور الجديدة:</span>
                                    <input
                                        type="text"
                                        name="password"
                                        value={editData.password}
                                        onChange={handleEditChange}
                                    />
                                </label>
                                <label>
                                    <span>العنوان الوظيفي:</span>
                                    <input
                                        type="text"
                                        name="title"
                                        value={editData.title}
                                        onChange={handleEditChange}
                                    />
                                </label>
                                <label>
                                <span>القسم:</span>
      <div className="multi-select-dropdown1">
        <button className="dropdown-toggle2" onClick={toggleDropdown}>


            {/* Display selected recipients or default placeholder */}
          {addData.departmentName.length > 0 
            ? addData.departmentName.join(', ') 
            : 'اختر القسم ...'}
          <svg
            className={`arrow-icon2 ${isDropdownOpen ? 'open2' : ''}`}
            width="15"
            height="10"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M7 10l5 5 5-5H7z" fill="#000" />
          </svg>
          
        </button>
       
            {isDropdownOpen && (
            <div className="dropdown-menu2">
                 {departments.map((department, index) => (
                <label key={index} className="dropdown-item1">
                    <input
                        type="checkbox"
                        value={department}
                        checked={addData.departmentName.includes(department)}
                        onChange={handleCheckboxChange}
                    />
                    {department}
                  </label>
                ))}
              </div>
            )}
          </div>
                                </label>
                                <div className="edit-actions">
                                    <button className="save-button" onClick={handleSaveClick}>حفظ</button>
                                    <button className="cancel-button" onClick={handleCancelClick}>إلغاء</button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <div className="user-list-container">
                    <div className="header2">
                        <div className="header-content">
                            <FaUsers alt="Icon" className="header-icon" />
                            <h1 className="header-text2">المستخدمين</h1>
                        </div>
                        <div className="actions1">
                            <button className="edit-button" onClick={handleAddClick}>
                                <img src={pencil} alt="add" className="button-icon" />
                                اضافة
                            </button>
                        </div>
                    </div>
                    <div className="search-bar">
                        <button className="search-button">
                            <svg width="20" height="20" viewBox="0 0 24 24">
                                <path d="M9.5 3c-3.6 0-6.5 2.9-6.5 6.5s2.9 6.5 6.5 6.5c1.4 0 2.7-.4 3.8-1.2l5.7 5.7 1.4-1.4-5.7-5.7c.8-1.1 1.2-2.4 1.2-3.8 0-3.6-2.9-6.5-6.5-6.5zm0 2c2.5 0 4.5 2 4.5 4.5s-2 4.5-4.5 4.5-4.5-2-4.5-4.5 2-4.5 4.5-4.5z" />
                            </svg>
                        </button>
                        <input type="text" placeholder="بحث" className="search-input" />
                        <button className="filter-button">
                            <svg width="20" height="20" viewBox="0 0 24 24">
                                <path d="M3 4c-1.1 0-1.99.9-1.99 2l-.01 2c0 .71.37 1.36.96 1.74l5.49 3.28v5.49c0 .6.34 1.16.88 1.41.54.26 1.18.2 1.66-.14l2-1.33c.43-.28.71-.77.71-1.29v-4.14l5.49-3.28c.59-.38.96-1.03.96-1.74v-2c0-1.1-.9-2-2-2h-16zm0 2h16v2l-6 3.6v4.4l-2 1.33v-5.73l-6-3.6v-2z" />
                            </svg>
                        </button>
                    </div>
                    <table className="income-table">
                        <thead>
                            <tr>
                                <th className="user-cell">الاسم</th>
                                <th className="user-cell">اسم المستخدم</th>
                                <th className="user-cell">البريد الإلكتروني</th>
                                <th className="user-cell">العنوان الوظيفي</th>
                                <th className="user-cell">القسم</th>
                                <th className="user-cell">الارشيف</th>
                                
                                
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item) => (
                                <tr
                                    key={item.id}
                                    onClick={() => handleRowClick(item)}
                                    className={`clickable-row ${selectedItem === item ? 'selected' : ''}`}
                                >
                                    <td className="user1-cell">{item.name}</td>
                                    <td className="user1-cell">{item.username}</td>
                                    <td className="user1-cell">{item.email}</td>
                                    <td className="user1-cell">{item.title}</td>
                                    <td className="user1-cell">{item.departmentName}</td>
                                    <td>
                                    <input
     className='user1-cell'
     type="checkbox"
     checked={item.canSeeArchive} // This will now reflect the specific user's status
     onChange={(e) => handleCheckboxChange2(e, item.username)} // Pass the username to the handler
     onClick={(e) => e.stopPropagation()} // Prevent row click event
   />
   {item.canSeeArchive}
</td>

                                    
                                </tr>
                               
                                
                                
                            ))}
                        </tbody>

                     
                    </table>
                    {isAdding && (
                        <div className="edit-overlay">
                            <div className="add-form">
                                <h2>اضافة مستخدم جديد</h2>
                                <label>
                                    <span>الاسم:</span>
                                    <input
                                        type="text"
                                        name="name"
                                        value={addData.name}
                                        onChange={handleAddChange}
                                    />
                                </label>
                                <label>
                                    <span>اسم المستخدم:</span>
                                    <input
                                        type="text"
                                        name="username"
                                        value={addData.username}
                                        onChange={handleAddChange}
                                    />
                                </label>
                                <label>
                                    <span>البريد الإلكتروني:</span>
                                    <input
                                        type="text"
                                        name="email"
                                        value={addData.email}
                                        onChange={handleAddChange}
                                    />
                                </label>
                                <label>
                                    <span>كلمة المرور:</span>
                                    <input
                                        type="password"
                                        name="password"
                                        value={addData.password}
                                        onChange={handleAddChange}
                                    />
                                </label>
                                <label>
                                    <span>العنوان الوظيفي:</span>
                                    <input
                                        type="text"
                                        name="title"
                                        value={addData.title}
                                        onChange={handleAddChange}
                                    />
                                </label>
                                <label>
                                    <span>القسم:</span>
                                    <div className="multi-select-dropdown1">
            <button className="dropdown-toggle2" onClick={toggleDropdown}>
            {addData.departmentName.length > 0 
                                                ? addData.departmentName.join(', ') 
                                                : 'اختر القسم ...'}
              <svg className={`arrow-icon2 ${isDropdownOpen ? 'open2' : ''}`} width="15" height="10" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 10l5 5 5-5H7z" fill="#000" />
              </svg>
            </button>
            {isDropdownOpen && (
            <div className="dropdown-menu2">
                 {departments.map((department, index) => (
                <label key={index} className="dropdown-item1">
                    <input
                        type="checkbox"
                        value={department}
                        checked={addData.departmentName.includes(department)}
                        onChange={handleCheckboxChange}
                    />
                    {department}
                  </label>
                ))}
              </div>
            )}
          </div>
                                </label>
                                <div className="edit-actions">
                                    <button className="save-button" onClick={handleSaveClick}>اضافة</button>
                                    <button className="cancel-button" onClick={handleCancelClick}>إلغاء</button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default Users;
